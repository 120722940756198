import SectionOne from "./section_1/SectionOne";
import SectionTwo from "./section_2/SectionTwo";
import SectionTrhee from "./section_3/SectionTrhee";

import MobileNavbar from "../../layouts/MobileNavbar";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import React from "react";
import ContactComponent from "../shared-components/contactComponent/ContactComponent";
import SectionTwoResponsive from "./section_2/SectionTwoResponsive";
import SectionTrheeResponsive from "./section_3/SectionTrheeResponsive";

export default function Branding({ isMobile }) {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  return (
    <>
      <Navbar
        isMobile={isMobile}
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
      />
      <h1 className="position-absolute opacity-0">
        Empresa de diseño y desarrollo web en Argentina
      </h1>
      {isMobile ? (
        <>
          {/* Renderiza componentes para mobile */}
          <MobileNavbar menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
          <SectionOne />
          <SectionTwoResponsive />
          <SectionTrheeResponsive />
          <ContactComponent isMobile={isMobile} lottieContact={true} />
        </>
      ) : (
        <>
          {/* Renderiza componentes para desktop */}
          <SectionOne />
          <SectionTwo />
          <SectionTrhee />
          <ContactComponent isMobile={isMobile} lottieContact={true} />
        </>
      )}

      <Footer />
    </>
  );
}
