import React from 'react'

import webVideo from './../../assets/img/web.mp4'
export default function GraciasTotales() {
    return (
        <section id="banner-proyectos" className="position-relative d-flex">
            <div className="contenedor-video  position-fixed">
                <div className="gradiente position-fixed"></div>
                <video muted playsInline autoPlay loop>
                    <source className="embed-responsive-item position-fixed" loading="lazy" src={webVideo} type="video/mp4" />
                </video>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="ff-circularLight mb-0">Gracias por tu consulta</h3>
                        <p>Nos estaremos contactando a la brevedad</p>
                        <a href="https://www.chimpance.digital/portfolio-web/" target="_blank" className="btn btn-white" rel="noopener noreferrer">Ver proyectos</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
