import React  from 'react';
import { NavLink } from 'react-router-dom'
import './article2.css';
import BannerComponent from './bannerComponent';
import MultimediaComponent from './MultimediaComponent';
import ContentComponent from './contentComponent';
import Apendix from './Apendix1';



const ArticleDetail = ({ article }) => {

  const arrowSvg = <svg  className="article_body_Container__backnav_arrow" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M340.864 149.312a30.59 30.59 0 0 0 0 42.752L652.736 512L340.864 831.872a30.59 30.59 0 0 0 0 42.752a29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"/></svg>

  return (
    <div className="article">
      <div className="article__fondo">
        <div className="article__fondo__contenedor0"></div>
        <h1 className="article__fondo__title">{article.title}</h1>
        <div className="fondo__contenedor1">
          <img src="/images/selva1.svg" alt="jungla de fondo" className="fondo__selva1" />
        </div>
        <div className="fondo__contenedor2">
          <img src="/images/selva2.svg" alt="jungla de fondo" className="fondo__selva2" />
        </div>
      </div>

      <div className='article_body'>
        <div className='article_body_Container container'>
        <div className='article_body_Container__backnav'><NavLink className="article_body_Container__backnav__link" to="/">Chimpancé Web</NavLink>{arrowSvg}<NavLink className='article_body_Container__backnav__link' to="/blog">Blog</NavLink>{arrowSvg} {article.title}</div>
          <p className='article_body_Container__title'>chimpancé digital</p>
          <ContentComponent
            content={article.introduction}
          />

        <Apendix headers={article.indexHeaders}/>

          {article.content && article.content.map((item) => {
            switch (item.__component) {
              case 'content.banner':
                return (
                  <BannerComponent
                    key={item.id}
                    bannerTitle={item.bannerTitle}
                    bannerContent={item.bannerContent}
                    bannerLink={item.bannerLink}
                    type={item.type}
                    articleSlug={article.slug}
                  />
                );
              case 'content.multimedia':
                return (
                  <MultimediaComponent
                    key={item.id}
                    alternativeText={item.alternativeText}
                    file={item.file}
                    type={item.type}
                  />
                );
              case 'content.content':
                return (
                  <ContentComponent
                    key={item.id}
                    content={item.content}
                  />
                );
              default:
                return null;
            }
          })}
          <ContentComponent
            content={article.conclusion}
          />
          {article.lastContent && article.lastContent.map((item) => {
            switch (item.__component) {
              case 'content.banner':
                return (
                  <BannerComponent
                    key={item.id}
                    bannerTitle={item.bannerTitle}
                    bannerContent={item.bannerContent}
                    bannerLink={item.bannerLink}
                    type={item.type}
                    articleSlug={article.slug}
                  />
                );
              case 'content.multimedia':
                return (
                  <MultimediaComponent
                    key={item.id}
                    alternativeText={item.alternativeText}
                    file={item.file}
                    type={item.type}
                  />
                );
              case 'content.content':
                return (
                  <ContentComponent
                    key={item.id}
                    content={item.content}
                  />
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  )
};

export default ArticleDetail;